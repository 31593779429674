<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.division_thematic_area') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="5" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('globalTrans.organization')"
                  label-for="org_id"
              >
                  <b-form-select
                      plain
                      id="org_id"
                      :options="orgList"
                      v-model="search.org_id"
                      placeholder=""
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="5" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro.office_type')"
                  label-for="office_type_id"
              >
                  <b-form-select
                      plain
                      id="org_id"
                      :options="officeTypeList"
                      v-model="search.office_type_id"
                      placeholder=""
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col lg="6" md="5" sm="12" xs="12">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro.office_namel')"
                  label-for="org_id"
              >
                  <b-form-select
                      plain
                      id="org_id"
                      :options="officeList"
                      v-model="search.office_id"
                      placeholder=""
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
        </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.division_thematic_area') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      <span class="capitalize">{{ getOrgName(data.item.org_id) }}</span>
                    </template>
                    <template v-slot:cell(office_type_id)="data">
                      <span class="capitalize">{{ getColumnNameOt($store.state.commonObj.officeTypeList, data.item.office_type_id) }}</span>
                    </template>
                    <template v-slot:cell(office_id)="data">
                      <span class="capitalize">{{ getColumnNameOt($store.state.commonObj.officeList, data.item.office_id) }}</span>
                    </template>
                    <template v-slot:cell(thematic_area_name)="data">
                      <span class="capitalize">{{ getThematicArea(data.item.thematic_area_id) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 border" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        <!-- <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                            <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                        </b-button> -->
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchDivisionThematicAreaList, researchDivisionThematicAreaToggleStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        org_id: 0,
        office_type_id: 0,
        office_id: 0
      },
      officeTypeList: [],
      officeList: []
    }
  },
  computed: {
    ...mapGetters({
        authUser: 'Auth/authUser'
    }),
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.division_thematic_area') + ' ' + this.$t('globalTrans.entry') : this.$t('research_manage.division_thematic_area') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          { label: this.$t('org_pro.office_type'), class: 'text-left' },
          { label: this.$t('org_pro.office_namel'), class: 'text-left' },
          { label: this.$t('research_manage.thematic_area_name'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_id' },
          { key: 'office_type_id' },
          { key: 'office_id' },
          { key: 'thematic_area_name' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_id' },
          { key: 'office_type_id' },
          { key: 'office_id' },
          { key: 'thematic_area_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  async created () {
    // this.search = Object.assign({}, this.search, {
    //   org_id: this.authUser.org_id
    // })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
      // if (this.$store.state.Auth.authUser.is_org_admin !== 1) {
      //   this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
      // }
    }
    this.loadData()
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal, oldVal) {
      this.officeTypeList = this.getOfficeTypeList(newVal)
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getThematicArea (data) {
      const thematicAreaId = []
      data.forEach((item, key) => {
        const itThere = this.getColumnNameOt(this.$store.state.AgriResearch.commonObj.thematicAreaList, parseInt(item))
        thematicAreaId.push(itThere)
      })
      return thematicAreaId.toString()
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
      return officeTypeList
    },
    getOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getColumnNameOt (list, groupId) {
      const obj = list.find(item => parseInt(item.value) === parseInt(groupId))
      if (typeof obj !== 'undefined') {
        return obj.text
      } else {
          return ''
      }
    },
    async searchData () {
        this.loadData()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, researchDivisionThematicAreaToggleStatus, item, 'agri_research', 'thematicAreaList')
      // this.changeStatus(agriResearchServiceBaseUrl, trainingTypeToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, researchDivisionThematicAreaList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            // this.$store.dispatch('setList', this.dataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getOrgName (id) {
        const Obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === parseInt(id))
        if (Obj) {
            if (this.$i18n.locale === 'en') {
                return Obj.text_en
            } else {
                return Obj.text_bn
            }
        } else {
                return ''
        }
    }
    // dataList (data) {
    //   const orgList = this.$store.state.orgList
    //   let tmpData = {}
    //   const listData = data.map(item => {
    //     tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
    //     const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
    //     return Object.assign({}, item, orgData)
    //   })
    //   return listData
    // }
  }
}
</script>
