<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                          <ValidationProvider name="Organization" rules="required|min_value:1">
                              <b-form-group
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="researchDivisionThematicArea.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office Type" rules="required|min_value:1">
                              <b-form-group
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="researchDivisionThematicArea.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Office" rules="required|min_value:1">
                            <b-form-group
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.office_namel')}} <span class="text-danger">*</span>
                                </template>
                                <v-select name="region_office_id"
                                  v-model="researchDivisionThematicArea.office_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= officeList
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                />
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                         <b-col lg="6" sm="12">
                          <ValidationProvider name="Thematic Area" rules="required|min_value:1">
                            <b-form-group
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('research_manage.thematic_area_name')}} <span class="text-danger">*</span>
                                </template>
                                <v-select name="thematic_area_id"
                                  v-model="researchDivisionThematicArea.thematic_area_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options= thematicAreaList
                                  :placeholder="$t('globalTrans.select')"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  :filter-by="myFilter"
                                  multiple
                                />
                                <div class="invalid-feedback d-block">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
            </template>
        </iq-card>
        <!-- <pre>{{ researchDivisionThematicArea }}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { researchDivisionThematicAreaStore, researchDivisionThematicAreaUpdate } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.researchDivisionThematicArea.org_id = this.$store.state.Auth.authUser.org_id
      this.researchDivisionThematicArea.office_type_id = this.$store.state.Auth.authUser.office_type_id
      this.researchDivisionThematicArea.office_id = this.$store.state.Auth.authUser.office_id
    }
    if (this.id) {
      const tmp = this.getEditingData()
      this.researchDivisionThematicArea = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      researchDivisionThematicArea: {
        org_id: 0,
        office_type_id: 0,
        office_id: null,
        thematic_area_id: []
      },
      officeTypeList: [],
      officeList: [],
      thematicAreaList: [],
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  watch: {
    'researchDivisionThematicArea.org_id': function (newVal, oldVal) {
      this.officeTypeList = this.getOfficeTypeList(newVal)
      this.thematicAreaList = this.getThematicArea(newVal)
    },
    'researchDivisionThematicArea.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    }
  },
  computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(el => el.status === 0)
      }
  },
  methods: {
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
      return officeTypeList
    },
    getThematicArea (orgId = null) {
      const thematicAreaList = this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
      if (orgId) {
        return thematicAreaList.filter(thematicArea => thematicArea.org_id === orgId)
      }
      return thematicAreaList
    },
    getOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.researchDivisionThematicArea.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${researchDivisionThematicAreaUpdate}/${this.id}`, this.researchDivisionThematicArea)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, researchDivisionThematicAreaStore, this.researchDivisionThematicArea)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
